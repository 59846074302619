import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Personalizza from "../../components/personalizza";
import Hero from "../../utils/hero";
import Link from "../../utils/link";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

// markup
const BibsPage = () => {
  return (
    <Page
      lang="es"
      title="Baberos desechables de tejido no tejido"
      description="Nuestros baberos desechables de TNT son un bonito servicio adicional muy apreciado y de uso rápido para platos con los que existe la posibilidad de ensuciarse."
      type="CollectionPage"
      readMore={["/es/muestra-gratuita"]}
      image="/bavagli-in-tnt.jpg"
    >
      <Hero
        title="Baberos desechables de tejido no tejido"
        description="Una solución agradable y cómoda para tus clientes, desde los más grandes hasta los más pequeños."
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Fragancia de menta"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h2">
                Formatos, colores y gráficas,
                <br />
                elija el babero desechable en TNT más adecuado para usted.
              </Typography>
              <Typography>
                Ofrecemos el producto tanto para adultos como para niños, para
                atar o llevar directamente al cuello.
              </Typography>
              <Typography>
                Nuestros baberos desechables de TNT son un bonito servicio
                adicional muy apreciado y de uso rápido para platos con los que
                existe la posibilidad de ensuciarse.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-nero-aragosta.jpg"
                alt="Babero tnt desechable maxi negro langosta"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Babero TNT desechable maxi negro "langosta"
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/es/muestra-gratuita"
                title="Descubre más sobre muestras gratuitas"
              >
                <Button variant="outlined" color="info" size="small">
                  Muestra gratuita
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-bianco-papillon.jpg"
                alt="Babero tnt desechable blanco pajarita"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Babero de TNT desechable blanco "pajarita"
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/es/muestra-gratuita"
                title="Descubre más sobre muestras gratuitas"
              >
                <Button variant="outlined" color="info" size="small">
                  Muestra gratuita
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-bianco-aragosta.jpg"
                alt="Babero tnt desechable blanco langosta"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Babero de TNT desechable blanco "langosta"
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/es/muestra-gratuita"
                title="Descubre más sobre muestras gratuitas"
              >
                <Button variant="outlined" color="info" size="small">
                  Muestra gratuita
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-bianco-bambino.jpg"
                alt="Babero desechable para bebé"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Babero desechable para bebé
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/es/muestra-gratuita"
                title="Descubre más sobre muestras gratuitas"
              >
                <Button variant="outlined" color="info" size="small">
                  Muestra gratuita
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/bavaglio-personalizzato.jpg"
                alt="Babero tnt desechable personalizado"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Babero TNT desechable personalizado
            </Typography>
            <Box mt={2}>
              <Link
                type="internal"
                href="/es/muestra-gratuita"
                title="Descubre más sobre muestras gratuitas"
              >
                <Button variant="outlined" color="info" size="small">
                  Muestra gratuita
                </Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Section>

      <Personalizza language="es" />
    </Page>
  );
};

export default BibsPage;
